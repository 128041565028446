<script>


import {
    required,
    helpers
  } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { mapActions } from "vuex";

//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
/*
import formAnagraficaCliente from "@/components/widgets/formAnagraficaCliente";
import projectManagerPV from "@/components/widgets/projectManagerPV";

import documentManager from "@/components/widgets/documentManager";
import surveyManager from "@/components/widgets/surveyManager";
import accountingManager from "@/components/widgets/accountingManager";
*/

//import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import pianificazioneFGAS from "@/components/widgets/worksites/pianificazioneFGAS";


import appConfig from "../../../../app.config";
import moment from 'moment'
import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';

  
  export default {
    name:'detailFGAS',
    props: [
      'projectID',
    ],
    setup() {     
      let tk = 'Bearer '+localStorage.getItem('tk')

      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Lista cantieri",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        data:{},
        worksite:{
            projectID: this.projectID,
            area:"",
            status:"init", // 'init'/'inprogress'/'pending'/'completed'/'problem'
            name:"",
            description:"",
            type:"",
            country:"",
            zipCode:"",
            city:"",
            address:"",
            address_number:"",
            additional_info_to_address:"",
            cordinate_system:"",
            longitude:"",
            latitude:"",
            year: new Date().getFullYear(),
            dateString:"",
            dateTmp:0,
        
            tasks:[],
            createdBy:this.$store.state.auth.currentUser,
            versions_history:{},
            assignedTo:[],
            startStr: "", 
            endStr: "",
            start: moment.unix(),
            end: "",
            start_tmp: moment.unix(),
            end_tmp: "",
            color: "",
            icon: "",
            url: "",
            geolocal:"",
            
            statusClass: "info",
            priority: "low",
            priorityClass: "success",
        },
        activeTab:'Localizzazione cantiere'
      };
    },
    components: {
      pianificazioneFGAS,
    },
    validations: {
      item: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
    mounted() {
    this.getItem()
        
    },
  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    convertTmpToDate(val){
        return moment.unix(val).format('LLL')
    },
    setTitle(par){
      this.activeTab = par
    },
    getParent(){
      this.getItem()
    },
    
    getItem() {
      
        axios.get(`${this.UrlServer}worksites/by-productid/${this.projectID}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
            this.data = response.data              
          }        
        }).catch(() => {  
          Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
        });
    },
    init(){
      axios.post(`${this.UrlServer}worksites/register`, this.worksite, {headers: { authorization:this.tk}}).then((response) => {
        if(response.data) {
          this.getItem()
          Swal.fire( this.$t("t-msg-worksite-tab-created-title") , this.$t("t-msg-worksite-tab-created-content"), "success");
        }
      }).catch(() => {  
        Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
      });
    },
    
  },
  beforeMount() {

    },
  };
</script>

<template>
 <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <div class="card-title mb-0 flex-grow-1">
                <div class="row">

                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                      <div class="input-group-prepend">
                        <span class="pr-5"><strong>Elenco interventi registrati</strong></span> 
                      </div>

                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
               
              >
                <label for="FormSelectSystem" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectSystem"
                  v-model="showTechProgect"
                />
              </div>
            </div>
          </div>
          <div class="card-body" >
           
            <template v-if="data">
              <template v-if="data.worksite == false && data.project != false">
                <label class="alert alert-info"><strong>Attenzione:</strong> {{data.message}}</label>
                <div class="mb-3">

                  <button type="button" class="btn btn-primary" @click="init()"><i class="bx bx-settings"></i>Avvia lavorazioni >></button>
                  </div>
              </template>
              <template v-else-if="data.worksite == false && data.project == false">
                <label class="alert alert-danger">{{data.message}}</label>
                <br>
                
              </template>
              <template v-else>
                <!-- Anagrafica cliente -->
                <div class="row" v-if="data.project">
                  <div class="col-lg-12">
                    <b-accordion class="custom-accordionwithicon-plus" id="accordionWithplusicon">
                    <b-accordion-item :title="'Intestatario impianto : '+ data.project.customerDetails.ragSoc" unvisible>
                      <div class="table-responsive">
                         <table class="table mb-0">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">Nominativo</th>
                                                      <th scope="col">Indirizzo</th>
                                                      <th scope="col">Città</th>
                                                      <th scope="col">Telefono</th>
                                                      <th scope="col">Email</th>
                                                      <th scope="col">#</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-if="data.project">
                                                    <tr>
                                                      <td>{{ data.project.customerDetails.ragSoc }}</td>
                                                      <td>{{ data.project.customerDetails.indirizzo }}</td>
                                                      <td>{{ data.project.customerDetails.citta }}</td>
                                                      <td>{{ data.project.customerDetails.telefono }}</td>
                                                      <td><a :href="'mailto:'+data.project.customerDetails.email">{{ data.project.customerDetails.email }}</a></td>
                                                      <td><button class="btn btn-info" title="Vedi anagrafica completa">Vedi</button></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                    </b-accordion-item>

                    <b-accordion-item title="Localizzazione Impianto" unvisible>
                      <div class="row" v-if="data.worksite">
                                      <div class="col-lg-12">
                                        <div class="card-body mb-50">

                                          <div class="row mb-50 pl-10">
                                            <div class="col-md-12 col-lg-4">
                                              <label for="code-field" class="form-label"><strong>Indirizzo</strong></label>
                                                <div class="input-group mb-1">
                                                  <input type="text" v-model="data.worksite.address" class="form-control" placeholder="Inserisci Indirizzo"  />
                                                    <div class="input-group-append">
                                                      <span class="input-group-text " ><i class="bx bxs-home mb-10"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-3">
                                              <label for="code-field" class="form-label"><strong>Città</strong></label>
                                                <div class="input-group mb-1">
                                                  <input type="text" v-model="data.worksite.city" class="form-control" placeholder="Inserisci Città"  />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text" ><i class="bx bxs-city mb-10"></i></span>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-2">
                                              <label for="code-field" class="form-label"><strong>C.A.P</strong></label>
                                                <div class="input-group mb-1">
                                                  <input type="text" v-model="data.worksite.zipCode" class="form-control" placeholder="Cap"  />
                                                    <div class="input-group-append">
                                                      <span class="input-group-text " ><i class="bx bx-current-location mb-10"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                          <div class="row mb-50 mt-20 pl-10">
                                            <div class="col-md-12 col-lg-2">
                                              <label for="code-field" class="form-label"><strong>Inizio lavori</strong></label>
                                                <div class="input-group mb-1">
                                                  <input type="date" v-model="data.worksite.address" class="form-control" placeholder="Inserisci Indirizzo"  />
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-2">
                                              <label for="code-field" class="form-label"><strong>Fine lavori</strong></label>
                                                <div class="input-group mb-1">
                                                  <input type="date" v-model="data.worksite.city" class="form-control" placeholder="Inserisci Città"  />
                                                </div>
                                            </div>
                                          </div> 
                                        </div>
                                      </div>
                                    </div>
                    </b-accordion-item>
                    </b-accordion>


                  </div>
                </div>

                

                <div class="pt-20">
                  <template v-if="data.project &&  data.worksite">
                    <pianificazioneFGAS :projectID="data.project._id" :worksiteID="data.worksite._id" />
                  </template>
                </div>  

              </template>
            </template>
            
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>   
</template>