<script>
import { ref } from "vue";
//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
/*
import formAnagraficaCliente from "@/components/widgets/formAnagraficaCliente";
import projectManagerPV from "@/components/widgets/projectManagerPV";

import documentManager from "@/components/widgets/documentManager";
import surveyManager from "@/components/widgets/surveyManager";
import accountingManager from "@/components/widgets/accountingManager";
*/
import detailFGAS from "@/components/widgets/worksites/detailFGAS";


//import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { mapActions } from "vuex";
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';
/*
import {
    required,
    helpers
} from "@vuelidate/validators";
*/
import useVuelidate from "@vuelidate/core";

export default {
  page: {
    title: "Dettaglio gestione interventi FGAS",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let files = ref([]);
    
    let tk = 'Bearer '+localStorage.getItem('tk')

    return { files, tk, v$: useVuelidate() };
  },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      showMainCustomer:false,
      showMainProgect:false,
      showMainSystems:false,
      showMainSurvey:false,
      showMainAdmin:false,
      showMainDocuments:false,
      showAmmProgect:false,
      showTechProgect:false,
      showAdvancedProgect:false,
      title: "Dettaglio gestione interventi FGAS",
      data:{},
      items: [
        {
          text: "Elenco cantieri",
          href: "/worksites/list-view",
        },
        {
          text: "Cantiere",
          href: `/worksite/detail/${this.$route.params.id}`,
        },
        {
          text: "Dettaglio gestione interventi FGAS",
          active: true,
        },
      ],
      
    };
  },
  mounted() {
   
  },

  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    
  },
  beforeMount() {
      axios.interceptors.response.use(
        (response) => {
            
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
      axios.get(`${serverConfig.EP}users/acl`, 
        { 
          headers: { 'authorization':this.tk},
          params: { 
            page: this.$router.currentRoute._rawValue.name,
          },
        },

      ).then(response => {
          if (response.data){
            if (response.data.user){
              if (response.data.user.roles_permissions.layout) {
                this.changeLayoutType({layoutType:response.data.user.roles_permissions.layout})
              }
            }
          }
      });
    },
  components: {
   
    Layout,
    PageHeader,
    detailFGAS,
    //ckeditor: CKEditor.component,
   // Multiselect
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <detailFGAS  :projectID="this.$route.params.id"/>
   
    <!--<div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Operazioni avanzate</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label for="FormSelectDefault" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  v-model="showAdvancedProgect"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
   <!-- <div class="row" v-if="showAdvancedProgect">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header align-items-center d-flex">
   
          </div>
          <div class="card-body" v-if="showMainProgect">
            
            <div class="mb-3">
              <label class="form-label" for="project-title-input"
                >Denominazione pratica</label
              >
              <input
                type="text"
                class="form-control"
                id="project-title-input"
                placeholder="Enter project title"
              />
            </div>

            <div class="mb-3">
              <label class="form-label" for="project-thumbnail-img"
                >Thumbnail Image</label
              >
              <input
                class="form-control"
                id="project-thumbnail-img"
                type="file"
                accept="image/png, image/gif, image/jpeg"
              />
            </div>

            <div class="mb-3">
              <label class="form-label">Descrizione</label>
              <ckeditor v-model="editorData" :editor="editor"></ckeditor>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <div class="mb-3 mb-lg-0">
                  <label for="choices-priority-input" class="form-label"
                    >Priorità</label
                  >

                  <Multiselect
                    v-model="value2"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="true"
                    :options="[
                      { value: 'Alta', label: 'High' },
                      { value: 'Media', label: 'Medium' },
                      { value: 'Bassa', label: 'Low' },
                    ]"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3 mb-lg-0">
                  <label for="choices-status-input" class="form-label"
                    >Status</label
                  >

                  <Multiselect
                    v-model="value1"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="true"
                    :options="[
                      { value: 'Inprogress', label: 'Inprogress' },
                      { value: 'Completed', label: 'Completed' },
                    ]"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div>
                  <label for="datepicker-deadline-input" class="form-label"
                    >Deadline</label
                  >

                  <flat-pickr
                    v-model="date"
                    :config="config"
                    class="form-control"
                  ></flat-pickr>
                </div>
              </div>
            </div>

          </div>
      
        </div>

        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Allegati</h5>
          </div>
          <div class="card-body">
            <div>
              
            </div>
            
          </div>
        </div>
       
        <div class="text-end mb-4">
          <button type="submit" class="btn btn-danger w-sm me-1">Rimuovi</button>
          <button type="submit" class="btn btn-secondary w-sm me-1">
            Draft
          </button>
          <button type="submit" class="btn btn-success w-sm">Crea</button>
        </div>
      </div>
     
      <div class="col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Privacy</h5>
          </div>
          <div class="card-body">
            <div>
              <label for="choices-privacy-status-input" class="form-label"
                >Status</label
              >
              <Multiselect
                v-model="value3"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="[
                  { value: 'Private', label: 'Private' },
                  { value: 'Team', label: 'Team' },
                  { value: 'Public', label: 'Public' },
                ]"
              />
            </div>
          </div>
         
        </div>
       
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Tags</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-categories-input" class="form-label"
                >Categories</label
              >
              <Multiselect
                v-model="value4"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="[
                  { value: 'Designing', label: 'Designing' },
                  { value: 'Development', label: 'Development' },
                ]"
              />
            </div>

            <div>
              <label for="choices-text-input" class="form-label">Skills</label>
              <Multiselect
                class="form-control"
                v-model="value"
                mode="tags"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="[
                  { value: 'UI/UX', label: 'UI/UX' },
                  { value: 'Figma', label: 'Figma' },
                  { value: 'HTML', label: 'HTML' },
                  { value: 'CSS', label: 'CSS' },
                  { value: 'Javascript', label: 'Javascript' },
                  { value: 'C#', label: 'C#' },
                  { value: 'Nodejs', label: 'Nodejs' },
                ]"
              />
            </div>
          </div>
          
        </div>
     
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Members</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-lead-input" class="form-label"
                >Team Lead</label
              >
              <Multiselect
                v-model="value5"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="[
                  { value: 'Brent Gonzalez', label: 'Brent Gonzalez' },
                  { value: 'Darline Williams', label: 'Darline Williams' },
                  { value: 'Sylvia Wright', label: 'Sylvia Wright' },
                  { value: 'Ellen Smith', label: 'Ellen Smith' },
                  { value: 'Jeffrey Salazar', label: 'Jeffrey Salazar' },
                  { value: 'Mark Williams', label: 'Mark Williams' },
                ]"
              />
            </div>

            <div>
              <label class="form-label">Team Members</label>
              <div class="avatar-group">
                <a
                  href="javascript: void(0);"
                  class="avatar-group-item shadow"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Brent Gonzalez"
                >
                  <div class="avatar-xs">
                    <img
                      src="@/assets/images/users/avatar-3.jpg"
                      alt=""
                      class="rounded-circle img-fluid"
                    />
                  </div>
                </a>
                <a
                  href="javascript: void(0);"
                  class="avatar-group-item shadow"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Sylvia Wright"
                >
                  <div class="avatar-xs">
                    <div class="avatar-title rounded-circle bg-secondary">
                      S
                    </div>
                  </div>
                </a>
                <a
                  href="javascript: void(0);"
                  class="avatar-group-item shadow"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Ellen Smith"
                >
                  <div class="avatar-xs">
                    <img
                      src="@/assets/images/users/avatar-4.jpg"
                      alt=""
                      class="rounded-circle img-fluid"
                    />
                  </div>
                </a>
                <a
                  href="javascript: void(0);"
                  class="avatar-group-item shadow"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Add Members"
                >
                  <div
                    class="avatar-xs"
                    data-bs-toggle="modal"
                    data-bs-target="#inviteMembersModal"
                  >
                    <div
                      class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary"
                    >
                      +
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        
        </div>
       
      </div>
    
    </div>
  -->
    <!-- end row -->
  </Layout>
</template>
